import styles from "../style";
import { discount, robot } from "../assets";
import GetStarted from "./GetStarted";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Hero = () => {
    return (
        <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
            <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>

                <AnimationOnScroll animateIn="animate__fadeIn" duration={5} delay={4000}>
                    <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
                        <img src={discount} alt="discount" className="w-[32px] h-[32px]" />
                        <p className={`${styles.paragraph} ml-2`}>
                            <span className="text-white">20%</span> Discount For{" "}
                            <span className="text-white">1 Month</span> Account
                    </p>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInLeft" duration={3} delay={2000}>
                    <div className="flex flex-row justify-between items-center w-full">
                        <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px]">
                            The Next <br className="sm:block hidden" />{" "}
                            <span className="text-gradient">Generation</span>{" "}
                        </h1>
                        <div className="ss:flex hidden md:mr-4 mr-0">
                            <GetStarted />
                        </div>
                    </div>

                    <h1 className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] w-full">
                        Payment Method.
                </h1>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInLeft" duration={3} delay={3000}>
                    <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                        Our team of experts uses a methodology to identify the credit cards
                        most likely to fit your needs. We examine annual percentage rates,
                        annual fees.
                </p>
                </AnimationOnScroll>
            </div>

            <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
                <AnimationOnScroll animateIn="animate__backInUp" duration={5}>
                    <img src={robot} alt="billing" className="w-[100%] h-[100%] relative z-[5]" />
                </AnimationOnScroll>

                {/* gradient start */}
                <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
                <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
                <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
                {/* gradient end */}
            </div>

            <div className={`ss:hidden ${styles.flexCenter}`}>
                <GetStarted />
            </div>
        </section>
    );
};

export default Hero;